import Service from "../Service";
import Vue from "vue";
const resource = "/CrewsAssistance/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "PaginationHarvester", parameters, {
            params: { requestID: requestID },
        });
    },

    SaveHarvester(obj, requestID) {
        return Service.post(resource + "SaveHarvesterFotoCheck", obj, {
            params: { requestID: requestID },
        });
    },

    SaveHarvesterExtern(obj, requestID) {
        console.log(obj);
        return Service.post(resource + "SaveHarvesterFotoCheck", obj, {
            params: { requestID: requestID },
        });
    },

    DeleteHarvester(CahID, requestID) {
        return Service.post(resource + "DeleteHarvester", "", {
            params: { CahID: CahID, requestID: requestID },
        });
    },

};