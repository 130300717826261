<template>
	<div>
		<v-card flat outlined>
			<v-card-text style="display: flex;">
				<!-- <s-toolbar
					color="warning"
					dark
					label="Asistencia Cuadrilla"
				></s-toolbar> -->
				Asistencia Cuadrilla
				<span>
					<!-- <v-col lg="2" md="2" cols="2">
						<v-btn
							small
							block
							rounded
							color="error"
							@click="btnDelete()"
							style="margin-top: -20px"
						>
							Eliminar
						</v-btn>
					</v-col> -->
				</span>
			</v-card-text>
			<v-card-actions>
				<v-row>
					<v-col lg="12" md="12" cols="12">
						<s-select-generic
							label="Fundos Programados"
							itemtext="FullDescription"
							itemvalue="cFundID"
							id="cFundID"
							:config="configFundAsignement"
							v-model="objFundAsignement"
							return-object
							full
							autocomplete
                            @input="inputFund($event)"
						>
						</s-select-generic>
						<!-- @input="recargar($event)" -->
					</v-col>

					<v-col cols="6" lg="3" md="3">
						<v-checkbox
							style="margin:0px;padding:0px;"
							label="Asistencia Externa"
							color="success"
							v-model="chkAssistenceExtern"
						>
						</v-checkbox>
					</v-col>

					<v-col lg="3" md="3" cols="6" v-if="chkAssistenceExtern == true">
						<s-text
							label="Nro. Documento"
							v-model="NumberDniExtern"
							@keyupEnter="btnGrabar(NumberDniExtern, namePersonExtern)"
						>
						</s-text>
					</v-col>

					<v-col lg="4" md="4" cols="10" v-if="chkAssistenceExtern == true">
						<s-text
							label="Persona"
							v-model="namePersonExtern"
							@keyupEnter="btnGrabar(NumberDniExtern, namePersonExtern)"
						>
						</s-text>
					</v-col>
					<v-col lg="2" md="2" cols="2" v-if="chkAssistenceExtern == true">
						<v-btn
							style="margin-top: 15px; margin-left: -10px;"
							small
							block
							color="primary"
							@click="btnGrabar(NumberDniExtern, namePersonExtern)"
						>
							Grabar
						</v-btn>
					</v-col>

					<v-col
						lg="4"
						md="4"
						cols="6"
						v-if="chkAssistenceExtern == false"
					>
						<s-search-person-crews
							ref="tbIasPerson"
							return-object
							@returnPerson="hadReturnPerson($event)"
							:autofocus="false"
						/>
					</v-col>

					<v-col
						lg="5"
						md="5"
						cols="12"
						v-if="chkAssistenceExtern == false"
					>
						<s-text disabled label="Persona" v-model="fullNamePerson">
						</s-text>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>

		<v-card flat outlined>
			<v-row>
				<v-col>
					<s-crud
						ref="gridAssitence02"
						:filter="filter"
						:config="configHarvesterList"
						noSearch
						title=""
						no-full
                        @rowSelected="rowSelected($event)"
						height="auto"
					>
						<template v-slot:Actions="{ row }">
							<v-btn
								@click="btnDelete(row)"
								x-small
								color="error"
								block
								rounded
							>
								<v-icon style="font-size: 16px !important"
									>fas fa-times</v-icon
								>
							</v-btn>
						</template>
					</s-crud>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>
<script>
	//Service
	import _sCrewsLoadAssistanceService from "../../../services/Technicalassistance/CrewsAssistanceLoadService";
	import _sCrewsAssistanceService from "../../../services/Technicalassistance/CrewsAssistenceService";

	//Component
	import SSelectGeneric from "@/components/Utils/SSelectGeneric.vue";
	import SSearchPersonCrews from "@/components/Utils/CrewsAssistance/sSearchPersonCrews.vue";

	export default {
		components: { SSelectGeneric, SSearchPersonCrews },

		data() {
			return {
				NtpID: 133,
				chkAssistenceExtern: false,
				configFundAsignement: {},
				objFundAsignement: [],
				fullNamePerson: "",
				NumberDniExtern: "",
				namePersonExtern: "",

				listHarvester: {
					cHarvesterDni: "",
					cHarvesterName: "",
					cUserCreateID: 0,
					cExternal: 0,
					HvsID: 0,
				},

				filter: {
					NtpID: 0,//this.$fun.getUserInfo().NtpID,
					HvsID: 0,
				},
				configHarvesterList: {
					service: _sCrewsAssistanceService,
					model: {
						CahID: "ID",
						Actions: ""	
					},
					headers: [
						{ text: "Accion", value: "Actions", width: 30 },
						{ text: "Doc. Identidad", value: "cHarvesterDni", width: 30 },
						{ text: "Cosechador", value: "cHarvesterName", width: 240 },
						{ text: "Cuadrilla", value: "cGangName", width: 100 },
					],
				},

                itemSelected: {}
			};
		},

		created() {
			this.configFundAsignement = {
				url: _sCrewsLoadAssistanceService.GetFundoAsignement(),
				params: {
					NtpID: this.$fun.getUserInfo().NtpID,
					search: "",
					requestID: this.$fun.getUserID(),
				},
			};
		},

		watch: {
			
            objFundAsignement(){
				
				if(this.objFundAsignement !== null){
					this.filter = {HvsID: this.objFundAsignement.HvsID, NtpID: this.$fun.getUserInfo().NtpID}
				}
               
            },

            chkAssistenceExtern(){
                this.fullNamePerson = "";
            },


		},

		methods: {

            rowSelected(item){
                
                if(item.length > 0){
                    this.itemSelected = item[0]
                }
                
            },

            inputFund(val){
				if(val !== null){
					this.filter.HvsID = val.HvsID
					this.filter.NtpID = this.$fun.getUserInfo().NtpID
				}	
                
            },

			btnGrabar(val, val2) {
				if (this.objFundAsignement == null) {
					this.$fun.alert(
						"Capataz no esta Programado a ningun Fundo - Lote",
						"warning",
					);
					return;
				}

				if (val.length == 0) {
					this.$fun.alert("Debe Ingresar un Doc. de Identidad", "warning");
					return;
				}
				if (val.length < 8) {
					this.$fun.alert("Doc. de Identidad no es valido", "warning");
					return;
				}

				if (val2.length == 0) {
					this.$fun.alert("Ingrese Nombre de Persona externa", "warning");
					return;
				}

				this.listHarvester.PrsID = 0;
				this.listHarvester.NtpID = this.NtpID;
				this.listHarvester.FagID = this.objFundAsignement.FagID;
				this.listHarvester.FltID = this.objFundAsignement.FltID;
				this.listHarvester.HvsID = this.objFundAsignement.HvsID;

				this.listHarvester.cHarvesterDni = val;
				this.listHarvester.cHarvesterName = val2;
				this.listHarvester.UsrCreateID = this.$fun.getUserID();
				this.listHarvester.SecStatus = 1;
				this.listHarvester.cExternal = this.chkAssistenceExtern ? 1 : 0;

				console.log("Guardar", this.listHarvester);

				_sCrewsAssistanceService
					.SaveHarvesterExtern(this.listHarvester, this.$fun.getUserID())
					.then(r => {
						this.$fun.alert(r.data, "warning");
						this.$refs.gridAssitence02.refresh();
						this.NumberDniExtern = "";
						this.namePersonExtern = "";
					});
			},

            btnDelete(item) {
				
				/* if (this.itemSelected.CahID == 0) {
					this.$fun.alert("No se ha seleccionado registro", "warning");
					return;
				} */

				if(item !== undefined) {
					this.$fun
						.alert("Seguro de Eliminar Asistencia?", "question")
						.then(val => {
							if (val.value) {
								_sCrewsAssistanceService
									.DeleteHarvester(
										item.CahID,
										this.$fun.getUserID(),
									)
									.then(r => {
										this.$fun.alert(
											"Eliminado Correctamente",
											"success",
										);
										this.$refs.gridAssitence02.refresh();
										this.fullNamePerson = "";
									});
							}
						});
				}
			},

			//Grabar Personal de la Empresa por FotoCheck
			hadReturnPerson(val) {
				if (this.objFundAsignement == null) {
					this.$fun.alert(
						"Capataz no esta Programado a ningun Fundo - Lote",
						"warning",
					);
					return;
				}

				if (val != null) {
					this.fullNamePerson = val.NtpFullName;

					this.listHarvester.PrsID = val.PrsID;
					this.listHarvester.NtpID = this.NtpID;
					this.listHarvester.cHarvesterDni = val.PrsDocumentNumber;
					this.listHarvester.cHarvesterName = val.NtpFullName;
					this.listHarvester.UsrCreateID = this.$fun.getUserID();
                    this.listHarvester.SecStatus = 1
					this.listHarvester.cExternal = this.chkAssistenceExtern ? 1 : 0;

					this.listHarvester.HvsID = this.objFundAsignement.HvsID;
					this.listHarvester.FagID = this.objFundAsignement.FagID;
                    this.listHarvester.FltID = this.objFundAsignement.FltID;

					_sCrewsAssistanceService
						.SaveHarvester(this.listHarvester, this.$fun.getUserID())
						.then(r => {
							this.$fun.alert(r.data, "warning");
							this.$refs.gridAssitence02.refresh();
							this.fullNamePerson = "";
						});
				}
			},

		},
	};
</script>
