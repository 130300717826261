import Service from "../Service";
import Vue from "vue";
const resource = "/CrewsAssistance/";

export default {
    GetForeman(val = "") {
        if (val !== undefined) {
            return Vue.prototype.$http.defaults.baseURL + resource + "GetForeman?cSupervisorDNI=" + val;
        } else {
            return;
        }

    },

    GetFundoAsignement() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetFundoAsignement";

    },

    GetSupervisor() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetSupervisor";
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "PaginationForHarvester", parameters, {
            params: { requestID: requestID },
        });
    },

    SaveHarvester(obj, requestID) {
        return Service.post(resource + "SaveHarvester", obj, {
            params: { requestID: requestID },
        });
    },

};